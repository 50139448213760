
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../../.sourceflow/metadata.json";
import { NextSeo } from 'next-seo';

export default function SeoHead({
    site_name = "Recruitment Agency",
    page_name = "Recruitment Agency · Omega",
    description = "Omega is able to source and select top talent across all energy sectors globally",
    canonical_link = "https://www.omegaresource.co.uk",
    og_image = "https://cdn.sourceflow.co.uk/yrun6ws5gipy33wqw14a8jn8g4ft",
    og_img_alt = "Recruitment Agency",
    robots=""
}) {
    return (
        <>
            <SourceFlowHead metaObject={metaObject}>
                <title>{page_name}</title>
                <meta name="title" content={page_name} />
                <meta name="description" content={description} />
                <meta name="robots" content={robots} />
                <link rel="canonical" href={canonical_link} key="canonical" />
            </SourceFlowHead>

            <NextSeo
                openGraph={{
                    url: canonical_link,
                    title: page_name,
                    site_name: site_name,
                    description: description,
                    images: [
                        {
                            url: og_image,
                            width: 1200,
                            height: 600,
                            alt: og_img_alt,
                            type: 'image/jpg',
                        }
                    ],
                }}
            />
        </>
    )
}
