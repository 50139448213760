import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { simple_pages_helper } from "@/helpers/simple_pages_helper";
import { blog_helper } from "@/helpers/blog_helper";
import { statistics_helper } from "@/helpers/statistics_helper";
import { jobs_helper } from "@/helpers/jobs_helper";
import SeoHead from "@/components/SeoHead";
import { getColorVariant } from "@/functions/getColorVariant";

export default function Page({ content }) {
  return (
    <>
      <SeoHead canonical_link={`https://www.omegaresource.co.uk/`} />
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  const page = simple_pages_helper.find("home");

  if (!page) {
    return {
      notFound: true,
    };
  }
  
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "HomeHero",
          skipFade: true,
          props: {
            logo: "/images/OmegaLogo-white.png",
            description: page.subtitle,
            backgrounds: page.background,
          },
        },
        ...page.stats.map(item => {
          return  {
            component: "Stats",
            props: {
              title: item.title || "",
              highlight: item.highlight || "",
              color_variant: getColorVariant(item),
              items: statistics_helper.fetch(),
            },
          }
        }),
        ...page.content.map(content => {
          if (!(!content.location || content.location == 1)) {
            return {}
          } else {
            return {
              component: "ContentSection",
              props: {
                title: content.title || "",
                highlight: content.highlight || "",
                description: content.description || "",
                image: content.image || "",
                alignment: content.alignment || "",
                button1_link: content.button1_link || "",
                button1_text: content.button1_text || "",
                button2_link: content.button2_link || "",
                button2_text: content.button2_text || "",
                color_variant: getColorVariant(content),
                alt_text: content.alt_text || "",
              }
            }
          }
        }),
        ...page.sectors.map(sector => {
          return  {
            component: "Sectors",
            props: {
              title: sector.title || "",
              highlight: sector.highlight || "",
              color_variant: getColorVariant(sector),
            },
          }
        }),
        ...page.jobs_section.map(item => {
          return  {
            component: "Jobs",
            props: {
              title: item.title || "",
              highlight: item.highlight || "",
              color_variant: getColorVariant(item),
              items: jobs_helper.fetch(),
            },
          }
        }),
        ...page.logos.map(item => {
          return  {
            component: "Logos",
            props: {
              title: item.title || "",
              highlight: item.highlight || "",
              color_variant: getColorVariant(item),
            },
          }
        }),
        ...page.insights.map(item => {
          return  {
            component: "Insights",
            props: {
              title: item.title || "",
              highlight: item.highlight || "",
              items: blog_helper.fetch(),
              color_variant: getColorVariant(item),
            },
          }
        }),
        ...page.content.map(content => {
          if (!(content.location && content.location == 2)) {
            return {}
          } else {
            return {
              component: "ContentSection",
              props: {
                title: content.title || "",
                highlight: content.highlight || "",
                description: content.description || "",
                image: content.image || "",
                alignment: content.alignment || "",
                button1_link: content.button1_link || "",
                button1_text: content.button1_text || "",
                button2_link: content.button2_link || "",
                button2_text: content.button2_text || "",
                color_variant: getColorVariant(content),
                alt_text: content.alt_text || "",
              }
            }
          }
        }),
      ],
    },
  };
}
